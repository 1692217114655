<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="2" class="f_w tit">条目检索</el-col>
      <el-col :span="6">
        <el-input v-model="srhStr" size="small" placeholder="请输入搜索关键词" maxlength="66" clearable></el-input>
      </el-col>
      <el-col :span="9">
        <el-button size="small" type="primary" @click="querySysOriginalDescriptionAll">检索</el-button>
        <span class="m_l1 unsatisfy">对检索结果不满意？试试<el-button size="small" type="text"
            @click="advancedQuery">高级检索</el-button></span>
      </el-col>
      <el-col :span="3">
        <!--                <el-dropdown class="m_l1">-->
        <!--                    <el-button type="primary" size="small">-->
        <!--                        智能操作<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
        <!--                    </el-button>-->
        <!--                    <el-dropdown-menu slot="dropdown">-->
        <!--                        <el-dropdown-item @click.native="collection" :disabled="dataListSelections.length <= 0">收藏</el-dropdown-item>-->
        <!--                        <el-dropdown-item @click.native="borrowing" :disabled="dataListSelections.length <= 0">借阅</el-dropdown-item>-->
        <!--                    </el-dropdown-menu>-->
        <!--                </el-dropdown>-->
        <el-button size="small" :disabled="dataListSelections.length == 0" plain @click="collection">收藏
        </el-button>
        <el-button size="small" :disabled="dataListSelections.length == 0" plain @click="borrowing">借阅</el-button>
      </el-col>
      <el-col :span="4" class="text_right">
        <el-button-group>
          <el-button size="small" plain @click="back">返回</el-button>
          <el-button size="small" plain icon="el-icon-refresh" @click="querySysOriginalDescriptionAll"></el-button>
          <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <!--标签-->
    <!--        <el-row :gutter="10" style="margin-top: 10px">-->
    <!--            <el-col :span="2" class="f_w tit">标签</el-col>-->
    <!--            <el-col :span="22">-->
    <!--                <el-tag v-for="item in biaoList" :key="item.id" @click="biaoqian(item)"-->
    <!--                        :class="['xuantiang',biaoSelect=={}?{}:biaoSelect.id == item.id ? 'xuanzhog' : '']">{{ item.name-->
    <!--                    }}-->
    <!--                </el-tag>-->
    <!--            </el-col>-->
    <!--        </el-row>-->
    <!--标签-->
    <div class="exhibition">
      <el-collapse accordion v-model="labelNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="flex_b_c exhibition-select" style="width: 100%">
              <div class="exhibition-title">
                <div class="xiaoshutiao"></div>
                标签<span class="exhibition-select">{{ biaoSelect.name }} ({{ total }})条</span>
              </div>
              <div>{{ labelNames.length == 0 ? '展开' : '收缩' }}</div>
            </div>
          </template>
          <div style="padding: 0 20px">
            <el-row :gutter="20" style="margin-bottom: 20px">
              <el-col :span="6">
                <el-input v-model="labelName" size="small" maxlength="66" placeholder="请输入标签名称" clearable></el-input>
              </el-col>
              <el-col :span="3">
                <el-button @click="labelIndexQueryAll" style="margin-right: 60px" size="small" type="primary"
                  icon="el-icon-search">查询
                </el-button>
              </el-col>
            </el-row>
            <div class="flex_b_c">
              <div style="font-size: 50px; cursor: pointer;" @click="labelOn">
                <i :class="['el-icon-arrow-left', labelPageNo == 1 ? 'hui' : 'blue']"></i>
              </div>
              <div style="width: 100%;text-align: center;color: rgb(144,147,153)" v-if="!biaoList.length">暂无数据</div>
              <div @click="biaoqian(item)" v-for="item in biaoList" :key="item.id"
                :class="['checkBox xuantiang', biaoSelect.id == item.id ? 'select' : '']">
                <el-tooltip placement="top">
                  <div slot="content">{{ item.name }}</div>
                  <div class="wenojsnd">{{ item.name }}</div>
                </el-tooltip>
              </div>
              <div style="font-size: 50px;cursor: pointer;" @click="labelTo">
                <i :class="['el-icon-arrow-right', labelPageNo * labelPageSize >= labelTotal ? 'hui' : 'blue']"></i>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!--展览-->
    <div class="exhibition">
      <el-collapse accordion v-model="catalogueNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="flex_b_c exhibition-select" style="width: 100%">
              <div class="exhibition-title">
                <div class="xiaoshutiao"></div>
                展览<span class="exhibition-select"><span v-if="Object.values(catalogueShuju).length"
                    style="margin-right: 5px">·</span>{{ catalogueShuju.exhibitionName }} ({{ total }})条</span>
              </div>
              <div>{{ catalogueNames.length == 0 ? '展开' : '收缩' }}</div>
            </div>
          </template>
          <div style="padding: 0 20px">
            <el-row :gutter="20" style="margin-bottom: 20px">
              <el-col :span="6">
                <el-input v-model="catalogueName" size="small" maxlength="66" placeholder="请输入展览名称" clearable></el-input>
              </el-col>
              <el-col :span="3">
                <el-button @click="catalogueQuery" style="margin-right: 60px" size="small" type="primary"
                  icon="el-icon-search">查询
                </el-button>
              </el-col>
            </el-row>
            <div class="flex_b_c">
              <div style="font-size: 50px; cursor: pointer;" @click="exhibitionOn">
                <i :class="['el-icon-arrow-left', cataloguePageNo == 1 ? 'hui' : 'blue']"></i>
              </div>
              <div style="width: 100%;text-align: center;color: rgb(144,147,153)" v-if="!catalogueList.length">暂无数据</div>
              <div @click="catalogueSelect(item)" v-for="item in catalogueList" :key="item.id"
                :class="['checkBox xuantiang', catalogueShuju.id == item.id ? 'select' : '']">
                <div style="margin-right: 10px">·</div>
                <el-tooltip placement="top">
                  <div slot="content">{{ item.exhibitionName }}</div>
                  <div class="wenojsnd">{{ item.exhibitionName }}</div>
                </el-tooltip>
              </div>
              <div style="font-size: 50px;cursor: pointer;" @click="exhibitionTo">
                <i
                  :class="['el-icon-arrow-right', cataloguePageNo * cataloguePageSize >= catalogueTotal ? 'hui' : 'blue']"></i>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!--        目录-->
    <div class="exhibition">
      <el-collapse accordion v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="flex_b_c exhibition-select" style="width: 100%">
              <div class="exhibition-title">
                <div class="xiaoshutiao"></div>
                目录<span class="exhibition-select"><span v-if="Object.values(exhibitionShuju).length"
                    style="margin-right: 5px"></span>{{ exhibitionShuju.directoryName }} ({{ total }})条</span>
              </div>
              <div>
                {{ activeNames.length == 0 ? '展开' : '收缩' }}
              </div>
              <!--                            <el-button style="margin-right: 60px" size="mini" type="primary" icon="el-icon-search">查询</el-button>-->
            </div>
          </template>
          <el-row :gutter="20" style="padding: 0 20px">
            <el-col :span="6">
              <el-input v-model="catalogueSrh" maxlength="66" size="small" placeholder="请输入目录名称" clearable></el-input>
            </el-col>
            <el-col :span="3">
              <el-button @click="searchDirectoryList" style="margin-right: 60px" size="small" type="primary"
                icon="el-icon-search">查询
              </el-button>
            </el-col>
          </el-row>
          <div class="flex_b_c">
            <div style="font-size: 50px; cursor: pointer;" @click="directoryOn">
              <i :class="['el-icon-arrow-left', pageNo2 == 1 ? 'hui' : 'blue']"></i>
            </div>
            <div style="width: 100%;text-align: center;color: rgb(144,147,153)" v-if="!catalogueList2.length">暂无数据</div>
            <div @click="exhibitionSelect(item)" v-for="item in catalogueList2" :key="item.id"
              :class="['checkBox', 'flex_b_c', exhibitionShuju.id == item.id ? 'select' : '']" style="cursor: pointer;">
              <div style="width: 95%">
                <el-tooltip placement="top">
                  <div slot="content">{{ item.directoryName }}</div>
                  <div class="wenojsnd">{{ item.directoryName }}</div>
                </el-tooltip>
              </div>
            </div>
            <div style="font-size: 50px;cursor: pointer;" @click="directoryTo">
              <i :class="['el-icon-arrow-right', pageNo2 * pageSize2 >= total2 ? 'hui' : 'blue']"></i>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="lbaiogao">
      <div style=" margin-left: 5px;" class="flex_b_c">
        <div class="iuahdiu">
          <div class="xiaoshutiao"></div>
          原文
        </div>
        <div style="display: flex;margin: 5px 16px 0">
          <div @click="xuzidb(1)" :class="['left', hehsu == 1 ? 'selectaud' : 'adjfu']">
            <img width="50%" :src="shu1">
          </div>
          <div @click="xuzidb(2)" :class="['right', hehsu == 2 ? 'selectaud' : 'adjfu']">
            <img width="50%" :src="heng1">
          </div>
        </div>
      </div>
      <el-row :gutter="10">
        <div v-show="hehsu == 1" style="height: calc(100vh - 430px);width: 100%;overflow-y: auto;text-align: center;">
          <el-empty v-if="!fileList.length" description="暂无数据"></el-empty>
          <div v-for="(item, index) in fileList" :key="index" style="padding:0 10px">
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
              <div class="xiaojbghi" @click="view(item.id)">
                <div class="tupaineui">
                  <!-- src\assets\img\word\audio.png -->
                  <el-image v-if="item.fileFormat.toLowerCase() == 'mp4'" style="width: 100px"
                            :src="require('../../../../assets/img/word/audio.png')" :fit="'cover'"></el-image>
                  <el-image v-else-if="item.fileFormat.toLowerCase() == 'mp3'" style="width:  100px"
                            :src="require('../../../../assets/img/word/video.png')" :fit="'cover'"></el-image>
                  <el-image v-else-if="item.fileFormat.toLowerCase() == 'jpg' ||
                    item.fileFormat.toLowerCase() == 'png' || item.fileFormat.toLowerCase() == 'jpeg' ||
                    item.fileFormat.toLowerCase() == 'gif' || item.fileFormat.toLowerCase() == 'tiff'
                    || item.fileFormat.toLowerCase() == 'tif'" style="width:  100px"
                            :src="require('../../../../assets/img/word/img.png')" :fit="'cover'"></el-image>
                  <el-image v-else-if="item.fileFormat.toLowerCase() == 'pdf'" style="width: 100px"
                            :src="require('../../../../assets/img/word/pdf.png')" :fit="'cover'"></el-image>
                  <el-image v-else style="width:  100px" :src="require('../../../../assets/img/word/file.png')"
                    :fit="'cover'"></el-image>
                </div>
                <el-tooltip placement="top">
                  <div slot="content">{{ item.fileName }}</div>
                  <div class="listTitle">{{ item.fileName }}
                  </div>
                </el-tooltip>
                <div class="flex_b_c" style="margin: 10px 12px">
                  <div>
                    年度：{{ item.pyear }}
                  </div>
                </div>
                <div class="flex_b_c" style="margin: 10px 12px">
                  <div>
                    密级：{{ item.classification }}
                  </div>
                </div>
                <!--                    <div>-->
                <!--                        尺寸：纵：110.9厘米，横：42.1厘米-->
                <!--                    </div>-->
              </div>
            </el-col>
          </div>

        </div>
      </el-row>
      <el-table v-show="hehsu == 2" :data="fileList" size="small" v-loading="loading" height="calc(100vh - 425px)"
        @selection-change="selectionChangeHandle" class="table" ref="tableBox">
        <el-table-column type="selection" width="50" fixed />
        <el-table-column v-for="(item, index) in config" :key="item.id" :prop="item.fieldEnname" :label="item.recordName"
          show-overflow-tooltip v-if="item.onShow == 0">
          <template slot-scope="scope">
            <el-tag type="success" v-if="item.fieldEnname == 'fileFormat'">{{ scope.row[item.fieldEnname] }}
            </el-tag>
            <div v-else>{{ scope.row[item.fieldEnname] }}</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="view(scope.row.id)">查看</el-button>
            <!--                    <el-button size="mini" type="success" plain @click="download(scope.row)">下载</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-bottom:15px" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
        :current-page="pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!--列表设置拖拽排序弹窗-->
    <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
    <!--高级检索-->
    <AdvancedQuery ref="advancedQuery" @getDataList="searchGetDataList"></AdvancedQuery>
    <!--查看-->
    <DetailPage ref="detailPage"></DetailPage>
    <!--查看-->
    <BorrowForm ref="borrowForm"></BorrowForm>
  </div>
</template>

<script>
import DraggablePop from '@/components/draggable/draggablePop' // 导入排序弹窗
import AdvancedQuery from './advancedQuery' // 高级查询弹窗
import DetailPage from './detailPage' // 查看弹窗
import BorrowForm from './BorrowForm'
import Template from "../catalogue/template"; // 查看弹窗
export default {
  components: { Template, DraggablePop, AdvancedQuery, DetailPage, BorrowForm },
  data() {
    return {
      // 展览
      exhibitionOpt: [],
      exhibitionVal: '',
      exhibitionLabel: "",

      // 目录
      catalogueSrh: '',
      catalogueList: [],
      catalogueList2: [],
      pageNo2: 1,
      pageSize2: 4,
      total2: 0,

      pageNo: 1,
      pageSize: 10,
      total: 0,

      srhStr: '',
      moduleId: 4,   // 当前请求拖拽排序数据id
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      pageNo3: 1,
      pageSize3: 4,
      total3: 0,
      fileList: [],
      loading: false,


      dataListSelections: [],
      config: [],
      type: 0,
      dateList: [],

      //标签
      biaoList: [],
      biaoSelect: {},//标签选中数据
      labelPageNo: 1,
      labelPageSize: 4,
      labelTotal: 0,
      labelNames: [], //控制标签是否展开
      labelName: "",

      //展览
      catalogueShuju: {}, //展览选中数据
      catalogueNames: [], //控制是否展开
      catalogueName: '',//展览名称
      //展览分页
      cataloguePageNo: 1,
      cataloguePageSize: 4,
      catalogueTotal: 0,

      exhibitionShuju: {}, //目录选中数据
      activeNames: [], //控制是否展开
      exhibitionName: '',//目录名称
      //展览分页
      exhibitionPageNo: 1,
      exhibitionPageSize: 4,
      exhibitionTotal: 0,
      exhibitionList: [],

      //列表
      hehsu: 1,//1为详情。2为表
      shu1: require("@/assets/img/shu.png"),
      heng1: require("@/assets/img/heng.png"),
    }
  },
  mounted() {


    // this.getFileList();
    this.querySysOriginalDescriptionAll();
  },
  methods: {
    init(srhStr) {
      this.srhStr = srhStr;
      this.querySysOriginalDescriptionAll();
      this.labelIndexQueryAll();
      this.getCatalogue();
    },
    initExhibition(item, srhStr) {
      this.srhStr = srhStr;
      this.querySysOriginalDescriptionAll();
      this.labelIndexQueryAll();
      this.getCatalogue(item);
    },
    initBiaoqian(item, srhStr) {
      this.srhStr = srhStr;
      this.querySysOriginalDescriptionAll();
      this.labelIndexQueryAll(item);
      this.getCatalogue();
    },
    // 返回
    back() {
      this.$emit('getData')
    },
    // 查询展览
    getCatalogue(item) {
      this.catalogueShuju = {}
      this.$axios(this.api.common.queryExhibition, {
        'name': this.catalogueName,
        'pageNo': this.cataloguePageNo,
        'pageSize': this.cataloguePageSize,
      }, 'get').then(data => {
        if (data && data.status) {
          this.catalogueList = data.data.records;
          this.catalogueTotal = parseInt(data.data.total)
          if (item != null) {
            this.catalogueShuju.id = item.id
            this.catalogueShuju.exhibitionName = item.exhibitionName
          }
          // this.fileList = [];
          this.getDirectory();
          this.tableData();
        }
      })
    },
    searchDirectoryList() {
      this.pageNo2 = 1;
      // this.fileList = [];
      this.getDirectory();
    },
    exhibitionOn() {
      if (this.cataloguePageNo == 1) {
        return false
      }
      this.cataloguePageNo -= 1
      this.getCatalogue();
    },
    exhibitionTo() {
      if (this.cataloguePageNo * this.cataloguePageSize >= this.catalogueTotal) {
        return false
      }
      this.cataloguePageNo += 1
      this.getCatalogue();
    },
    labelOn() {
      if (this.labelPageNo == 1) {
        return false
      }
      this.labelPageNo -= 1
      this.labelIndexQueryAll();
    },
    labelTo() {
      if (this.labelPageNo * this.labelPageSize >= this.labelTotal) {
        return false
      }
      this.labelPageNo += 1
      this.labelIndexQueryAll();
    },
    labelIndexQueryAll2() {
      this.labelPageNo = 1
      this.labelIndexQueryAll();
    },
    //文件标签
    labelIndexQueryAll(item) {
      this.biaoList = []
      this.biaoSelect = {}
      this.$axios(this.api.common.labelIndexList, {
        "name": this.labelName,
        "state": 1,
        'pageNo': this.labelPageNo,
        'pageSize': this.labelPageSize,
      }, 'get').then(data => {
        if (data.status && data.data) {
          this.biaoList = data.data.records
          this.labelTotal = parseInt(data.data.total)
          this.tableData()
          if (item != undefined) {
            this.biaoSelect = item
          }

        }
      })
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    querySysOriginalDescriptionAll() {
      let that = this;
      this.$axios(this.api.catalogue.querySysOriginalDescriptionAll, {
        flag: 4,
        onDetails: 1
      }, 'get').then(data => {
        if (data) {
          this.config = data
          this.config.forEach(function (v) {
            v.fieldEnname = that.toHump(v.fieldEnname)
            if (v.fieldEnname == 'updateId') {
              v.fieldEnname = 'updateName'
            }
            if (v.fieldEnname == 'updateId') {
              v.fieldEnname = 'updateName'
            }
            if (v.fieldEnname == 'quanzongNo') {
              v.fieldEnname = 'exhibitionName'
            }
            // that.$set(that.inputForm,v.fieldEnname,"")
          })
          this.search();
        }
      })
    },
    // 检索
    search() {
      this.pageNo = 1
      this.type = 0
      this.tableData()
    },
    tableData() {
      // if(this.catalogueShuju || this.biaoSelect){
      this.loading = true
      this.$axios(this.api.common.queryAllByLimitRetrieve, {
        "name": this.srhStr,
        "exhibitionId": this.catalogueShuju.id,
        "directoryId": this.exhibitionShuju.id,
        "labelId": this.biaoSelect.id,
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
        'flag': 4,
        'onDetails': 1,
      }, 'get').then(data => {
        if (data && data.status) {
          this.fileList = data.data.records
          let that = this;
          this.fileList.forEach(function (v) {
            that.$set(v, "filePath", v.url);
            v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.length)
          })
          this.total = parseInt(data.data.total)
          this.loading = false
          this.$refs.tableBox.doLayout();
        }
      })
      // }
    },
    //收藏
    collection() {
      let fileIds = this.dataListSelections.map(item => {
        return item.id
      }).join(',')
      this.$axios(this.api.common.MyCollectionSave, {
        fileIds: fileIds
      }, 'post').then(data => {
        if (data.status) {
          this.$message.success("收藏成功")
        } else {
          this.$message.error("收藏失败")
        }
      })
    },
    //借阅
    borrowing() {
      console.log(this.dataListSelections.length);
      this.$refs.borrowForm.init(this.dataListSelections)
    },
    // 高级查询
    advancedQuery() {
      this.$refs.advancedQuery.init(this.config)
    },
    searchGetDataList(data) {
      this.pageNo = 1
      this.type = 1
      this.dateList = data
      this.getDataList(data);
    },
    //高级查询检索返回
    getDataList(data) {
      this.$axios(this.api.common.seniorQuerySQL, {
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
        'flag': 4,
        'onDetails': 1,
        "seniorQueries": data
      }, 'post').then(data => {
        if (data && data.status) {
          this.fileList = data.data.records
          let that = this;
          this.fileList.forEach(function (v) {
            that.$set(v, "filePath", v.url);
            v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.length)
          })
          this.total = parseInt(data.data.total)
          this.loading = false
          this.$refs.tableBox.doLayout();
        }
      })
    },
    // 查看
    view(row) {
      this.$refs.detailPage.init(row, 'itemRetrieval')
    },
    addWatermark(url) {
      let watermark = JSON.parse(sessionStorage.getItem('userInfo')).name + " " + this.$publicUtile.formatDate(new Date())
      let sourceFile = this.$getFileTemporary + JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
      // if (this.filePath.match(RegExp(/home/))) {
      //     sourceFile = url.substring(0, (url.indexOf("/")) + 1) + "home/fileTemporary/" + JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("/"), url.length)
      // } else {
      //     sourceFile = url.substring(0, (url.indexOf("\\")) + 1) + "fileTemporary\\" + JSON.parse(sessionStorage.getItem('userInfo')).id + url.substring(url.lastIndexOf("\\"), url.length)
      // }
      return new Promise((resolve, reject) => {
        this.$axios("zyd-catalogue/pdf/addWatermark", {
          "target": url,
          "sourceFile": sourceFile,
          "watermark": watermark
        }, 'post').then(data => {
          if (data.status) {
            resolve(data.data)
          } else {
            resolve("")
          }
        })
      })
    },
    // 下载
    download(row) {
      this.addWatermark(row.filePath).then(data => {
        let url = ""
        if (data) {
          // console.log("接口数据：",data);
          url = this.$getUrl() + data.substring(data.indexOf("\\"), data.length)
          // console.log("接口url:",this.fileDetailsForm.url);
        } else {
          url = row.url;  // 完整的url则直接使用
          // this.fileDetailsForm.url= this.$getUrl()+this.fileDetailsForm.url.substring(this.fileDetailsForm.url.indexOf("\\"),this.fileDetailsForm.url.length)
        }
        const a = document.createElement('a')
        //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob)
          // console.log(a.href)
          a.download = row.fileName // 下载文件的名字
          // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
          document.body.appendChild(a)
          a.click()
        })
      })

    },
    // 设置
    setTb() {
      this.setShow = true;
    },
    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.config = data;
      this.search();
      this.setShow = state;
    },
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 目录每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      if (this.type == 0) {
        this.tableData();
      } else {
        this.getDataList(this.dateList)
      }

    },
    // 目录当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      if (this.type == 0) {
        this.tableData();
      } else {
        this.getDataList(this.dateList)
      }
    },

    //标签点击
    biaoqian(item) {
      console.log(item)
      if (this.biaoSelect.id == item.id) {
        this.biaoSelect = {}
      } else {
        this.biaoSelect = item;
      }
      this.tableData();
    },

    //展览选中
    catalogueSelect(item) {
      // this.catalogueNames = [];
      if (this.catalogueShuju.id == item.id) {
        this.catalogueShuju = {}
      } else {
        this.catalogueShuju = item;
      }
      this.getDirectory()
      this.tableData();
    },


    //展览查询
    catalogueQuery() {
      this.cataloguePageNo = 1
      this.getCatalogue();
    },

    //列表
    xuzidb(num) {
      this.hehsu = num;
      if (num == 1) {
        this.shu1 = require("@/assets/img/shu.png");
        this.heng1 = require("@/assets/img/heng.png");
      } else {
        this.shu1 = require("@/assets/img/shu2.png");
        this.heng1 = require("@/assets/img/heng2.png");
      }
    },
    searchDirectoryList() {
      this.pageNo2 = 1;
      // this.fileList = [];
      this.getDirectory();
    },
    // 查询目录
    getDirectory() {
      this.catalogueList2 = []
      this.exhibitionShuju = {}
      if (this.catalogueShuju.id) {
        this.$axios(this.api.mana.queryAllByLimit, {
          'exhibitionId': this.catalogueShuju.id,
          'directoryName': this.catalogueSrh,
          "publishState": 0,
          'pageNo': this.pageNo2,
          'pageSize': this.pageSize2,
        }, 'get').then(data => {
          if (data && data.status) {
            this.catalogueList2 = data.data.records
            // this.exhibitionShuju.id = this.catalogueList2[0].id
            // this.exhibitionShuju.directoryName = this.catalogueList2[0].directoryName
            this.total2 = parseInt(data.data.total)
            // this.directoryId = this.catalogueList2[0].id
          }
        })
      }

      // }
    },
    //目录选中
    exhibitionSelect(item) {
      if (this.exhibitionShuju.id == item.id) {
        this.exhibitionShuju = {}
      } else {
        this.exhibitionShuju = item;
      }
      this.pageNo = 1
      this.tableData()
      // this.activeNames = [];
    },
    directoryOn() {
      if (this.pageNo2 == 1) {
        return false
      }
      this.pageNo2 -= 1
      this.getDirectory();
    },
    directoryTo() {
      if (this.pageNo2 * this.pageSize2 >= this.total2) {
        return false
      }
      this.pageNo2 += 1
      this.getDirectory();
    },
  }
}
</script>

<style scoped>
.tit {
  padding-top: 5px;
}

.unsatisfy {
  font-size: 12px;
}

.wordIcon {
  width: 16px;
  margin: 0 4px;
}


/*标签*/
.xuanzhog {
  border-color: #35FA42;
}

.xuantiang {
  cursor: pointer;
  display: flex;
  align-items: center;
}


/*展览*/
.exhibition {
  margin-top: 20px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(208, 208, 208, 100);
}

.exhibition-title {
  display: flex;
  align-items: center;
  color: black;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exhibition-select {
  margin-left: 20px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkBox {
  margin: 0 10px;
  width: 20%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid rgba(187, 187, 187, 100);
}

.hui {
  color: rgba(208, 208, 208, 100);
}

.blue {
  color: #3E8DF7;
}

.select {
  border: 1px solid #3E8DF7;
}

.wenojsnd {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/*列表*/
.lbaiogao {
  margin-top: 20px;
  width: 100%;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(215, 215, 215, 100);
}

.iuahdiu {
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-size: 20px;
  font-weight: bold;
}

.tupaineui {
  margin-bottom: 13px;
  box-sizing: border-box;
}

.listTitle {
  padding: 0 10px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 600;
}


.left {
  width: 50px;
  height: 37px;
  line-height: 49px;
  text-align: center;
  border-radius: 4px 0 0 4px;
}

.right {
  width: 50px;
  height: 37px;
  line-height: 46px;
  text-align: center;
  border-radius: 0 4px 4px 0;
}

.selectaud {
  background: #3E8DF7;
}

.adjfu {
  border: 1px solid rgba(187, 187, 187, 100);
}

.xiaojbghi {
  padding: 13px 18px;
  float: left;
  margin: 5px 0;
  width: 100%;
  height: 280px;
  line-height: 20px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgba(208, 208, 208, 100);
}
</style>
